.outroWrapper h2 {
  color: var(--10323D, #10323d);
  text-align: center;

  /* Heading 2 */
  font-family: "Proxima Nova";
  font-size: 94px;
  font-style: normal;
  font-weight: 700;
  line-height: 94px; /* 100% */
  letter-spacing: -2.82px;
  text-align: center;
  padding-top: 160px;
}
.outroWrapper {
  width: 100%;
  height: 100vh;
  position: relative;
}
