@font-face {
  font-family: Proxima Nova;
  src: url("./ProximaNova-Regular.otf");
}
@font-face {
  font-family: Proxima Nova Bold;
  src: url("./Mark\ Simonson\ -\ Proxima\ Nova\ Bold.otf");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Proxima Nova;
  background: #f4efe7;
}
* {
  margin: 0;
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.desktop {
  display: block;
}
.mobile {
  display: none;
}

@media screen and (max-width: 1200px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
