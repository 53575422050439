.homeWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.animationImages {
  width: 100%;
  object-fit: scale-down;
  height: 100vh;
}
