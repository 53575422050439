.Footer {
  display: flex;
  width: 100%;
  padding: 32px 40px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99999;
}
.Footer p {
  color: #10323d;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;

  line-height: 20px; /* 111.111% */
}
.Footer a {
  border-radius: 50px;
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 10px;
  background: #a293c1;
}
.Footer a svg {
  width: 20px;
  height: 20px;
}
.header a {
  border-radius: 50px;
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 10px;
  background: #a293c1;
}
.header a svg {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 1200px) {
  .mobileHidden {
    display: none;
  }
  .Footer {
    justify-content: space-between;
    background: #f4efe7;
    padding: 28px 30px;
  }
}
