.investment {
  position: relative;
  width: 100%;
  height: 100vh;
}
.contentWrapper {
  position: absolute;
  right: 160px;
  bottom: 260px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.contentInvestment {
  display: flex;
  width: 570px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}
.contentInvestment p {
  color: #10323d;
  font-family: "Proxima Nova";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}
.contentInvestment .line {
  width: 100%;
  height: 2px;
  opacity: 0.09;
  background: #143e4c;
}
