h1 {
  color: #10323d;
  font-family: "Proxima Nova Bold";
  font-size: 83px;
  font-style: normal;
  font-weight: 700;
  line-height: 85px; /* 102.41% */
  letter-spacing: -3.32px;
}
.Hero {
  position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100vh;
}
.Hero h1 {
  padding-left: 100px;
  padding-top: 163px;
}

.content {
  display: flex;
  width: 450px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 35px;
  position: absolute;
  bottom: 163px;
  right: 163px;
}
.content p {
  color: #10323d;
  font-family: "Proxima Nova";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}
.content .line {
  width: 100%;
  height: 2px;
  opacity: 0.09;
  background: #143e4c;
}

@media screen and (max-width: 1600px) {
  .Hero h1 {
    font-size: 64px;
  }
  h1 {
    font-size: 64px;
  }
  .content {
    right: 100px;
  }
  .contentWrapper {
    right: 100px !important;
  }
  .contentWrapperContact {
    left: 100px !important;
  }
  .contentWrapperContact p {
    font-size: 20px !important;
  }
  .contentInvestment p {
    font-size: 20px !important;
  }
  .content p {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1400px) {
  .Hero h1 {
    font-size: 52px;
    line-height: 52px;
  }
  h1 {
    font-size: 52px;
    line-height: 52px;
  }
  .investment {
    overflow: hidden;
    max-width: 100% !important;
  }
  .content {
    right: 50px;
  }
  .contentWrapper {
    right: 100px !important;

    width: 500px;
    right: 50px;
  }
  .contentWrapperContact {
    left: 100px !important;
  }
  .contentWrapperContact p {
    font-size: 18px !important;
    line-height: 22px;
  }
  .contentInvestment p {
    font-size: 18px !important;
    line-height: 22px;
    width: 500px;
  }
  .contentInvestment .line{
    width: 500px!important;
  }
  .content p {
    font-size: 18px !important;
    line-height: 22px;
  }
}
