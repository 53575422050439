.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  padding: 28px 40px;
  z-index: 99999;
  align-items: center;
  justify-content: space-between;
}
.header p {
  color: #10323d;
  text-align: right;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-style: normal;
  width: 152px;
  font-weight: 800;
  line-height: 20px; /* 100% */
}
.header img {
  width: auto;
}
.lineWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(0deg, #94cad3 0%, #94cad3 100%), #e9e7e3;
}
.beginnerLine {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #376a7e;
  z-index: 2;
  width: 0%;
  height: 10px;
}
@media screen and (max-width: 768px) {
  .header {
    background: #f4efe7;
    padding: 28px 30px;
  }
}
