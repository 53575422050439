.mobileHome {
  padding-inline: 15px;
  height: 100vh;
}
.contentMobile {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  margin-top: 40px;
}
.swiper-wrapper {
  height: 100vh;
}
.swiper {
  height: 100vh !important;
}
.contentMobile p {
  color: var(--10323D, #10323d);
  font-family: "Proxima Nova";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
}
.swiper-slide {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.contentMobile .line {
  width: 100%;
  height: 2px;
  opacity: 0.09;
  background: #143e4c;
}

.contactFlex {
  display: flex;
  align-items: center;
  gap: 20px;
}
